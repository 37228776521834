import Navbar from "./components/Navbar.tsx/Navbar";
import Hero from "./components/Hero/Hero";
import Number from "./components/Number/Number";
import Card from "./components/Card/Card";
import Display from "./components/Display/Display";
import Gallery from "./components/Gallery/Gallery";
import Question from "./components/Question/Question";
import Footer from "./components/Footer/Footer";
import "./App.css";
import "./index.css";
export default function App() {
  return (
    <div>
      <Navbar />
      <section id="home">
        <Hero />
      </section>
      <section id="about">
        <Number />
      </section>
      <section id="services">
        <Card />
      </section>
      <Display />
      <section id="gallery">
        <Gallery />
      </section>
      <Question />
      <Footer />
    </div>
  );
}
